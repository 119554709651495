import { useQuery } from 'react-query';
import { GET_CHANNELS } from '../../shared/api/channels';
import { LIST_USERS, UPDATE_ACCESS } from '../../shared/api/users';
import Table from '../../shared/components/NewTable/Table';
import useChannelQuery from '../../shared/hooks/useChannelQuery';
import { ChannelsDataType } from '../../strategy/types/channels';
import { User, UsersDataType } from '../types/users';

const renderCell = (
	row: User,
	columnId: string,
	channels: ChannelsDataType,
	refetchUsers: () => void
) => {
	switch (columnId) {
		case 'Name':
			return (
				<div>
					<span>{row.name}</span>
				</div>
			);
		case 'Email':
			return <span>{row.email}</span>;
		case 'Roles':
			return <span>{row.roles.join(', ')}</span>;
		case 'Channels':
			if (row.roles.includes('superadmin') || row.roles.includes('admin')) {
				return <span>All</span>;
			}

			// TODO KILIAN/WARD: proper select component like
			// * https://headlessui.com/react/listbox#selecting-multiple-values
			return (
				<form className="max-w-sm">
					<select
						multiple
						className="bg-ca-ghost-white border border-ca-gray text-ca-gray text-sm rounded-lg focus:border-ca-purple block w-full p-2.5"
						value={row.access.channels}
						onChange={async (e) => {
							await UPDATE_ACCESS(row.id, {
								channels: Array.from(
									e.target.selectedOptions,
									(option) => option.value
								),
							});
							refetchUsers();
						}}
					>
						{channels.map(({ name }) => (
							<option key={name} value={name}>
								{name}
							</option>
						))}
					</select>
				</form>
			);
		default:
			return null;
	}
};

const UserManagementTable = () => {
	const {
		isLoading: usersLoading,
		data: users,
		refetch: refetchUsers,
	} = useChannelQuery<UsersDataType, unknown, UsersDataType>(['users'], () =>
		LIST_USERS()
	);

	const { isLoading: channelsLoading, data: channels } = useQuery<
		ChannelsDataType,
		unknown,
		ChannelsDataType
	>('channels', GET_CHANNELS, {
		staleTime: 5 * 60 * 1000,
	});

	return (
		<Table
			loading={usersLoading || channelsLoading}
			rows={users ?? []}
			renderCell={(r, c) => renderCell(r, c, channels ?? [], refetchUsers)}
			rowKey="id"
			headings={[
				{
					id: 'Name',
					label: 'Name',
				},
				{
					id: 'Email',
					label: 'Email',
				},
				{
					id: 'Roles',
					label: 'Roles',
				},
				{
					id: 'Channels',
					label: 'Channel access',
				},
			]}
		/>
	);
};

export default UserManagementTable;
