import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, useLocation } from 'react-router-dom';

type AuthenticatedProps = {
	children: NonNullable<React.ReactNode>;
};

// TODO KILAN/WARD: This is a temp wrapper to hoist the redirect above the channel context. Revisit
// with Ward's updated routing.
const Authenticated = ({ children }: AuthenticatedProps) => {
	const { isLoading, isAuthenticated } = useAuth0();
	const location = useLocation();
	if (!isLoading && !isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: '/login',
					state: { from: location },
				}}
			/>
		);
	}

	return <>{children}</>;
};

export default Authenticated;
