import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useChannelStore from '../../data/channels';

import Dropdown from '../Dropdown/Dropdown';

const ChannelSelector = () => {
	const [displayNames, setDisplayNames] = useState();
	const { channels, activeChannel, setActiveChannel } = useChannelStore();
	const history = useHistory();
	const { pathname } = useLocation();

	useEffect(() => {
		try {
			const names = JSON.parse(window._ENV_.REACT_APP_CHANNEL_NAMES);

			if (names.constructor === {}.constructor) {
				setDisplayNames(names);
			}
		} catch (err) {
			setDisplayNames({});
		}
	}, []);

	if (channels.length <= 1) {
		// if there is only 1 channel, we do not need a selector
		return null;
	}

	const options = channels.map((c) => ({
		value: c,
		label: displayNames?.[c] || c,
	}));

	if (window._ENV_.REACT_APP_GLOBAL_CHANNEL_ENABLED) {
		options.push({
			value: 'global',
			label: 'Global',
		});
	}

	const handleChannelChange = (channel) => {
		if (channel === 'global') {
			history.push('/global');
		} else {
			setActiveChannel(channel);

			// navigate away so we don't get stuck in global mode
			history.push(window._ENV_.REACT_APP_DEFAULT_REDIRECT_PATH || pathname);
		}
	};

	const isGlobalMode = pathname.startsWith('/global');

	return (
		<Dropdown
			onChange={handleChannelChange}
			value={isGlobalMode ? 'global' : activeChannel}
			options={options}
			className="w-64"
		/>
	);
};

export default ChannelSelector;
