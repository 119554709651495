import { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import clsx from 'clsx';

import useChannelStore from '../../data/channels';
import HelpIcon from '../Icons/Help';
import UploadBold from '../Icons/UploadBold';
import UserManagementBold from '../Icons/UserManagementBold';
import PlatformSelect from '../PlatformSelect/PlatformSelect';
import Tooltip from '../Tooltip/Tooltip';
import LogOutButton from './LogOutButton';
import SidebarLink, { SidebarLinkProps } from './SidebarLink';

export interface SidebarLinkType extends SidebarLinkProps {
	id: string;
	isEnabled: boolean | [string];
}

interface Props {
	activePlatform: string;
	links: SidebarLinkType[];
}

// TODO KILIAN/WARD: This function duplicated in enabledRoutes. This should be moved to a shared
// util.
function checkEnabled(
	isEnabled: boolean | [string],
	activeChannel: string
): boolean {
	if (typeof isEnabled === 'boolean') {
		return isEnabled;
	}
	return isEnabled.includes(activeChannel);
}

const Sidebar: FunctionComponent<Props> = ({ activePlatform, links }) => {
	const { user } = useAuth0();
	const location = useLocation();
	const history = useHistory();
	const isGlobalMode =
		window._ENV_.REACT_APP_GLOBAL_CHANNEL_ENABLED &&
		location.pathname.startsWith('/global');
	const { activeChannel } = useChannelStore();

	const isSuperAdmin =
		user?.['https://crunchplatform.eu/roles']?.includes('superadmin');

	const activeLinks = links.filter(
		({ id, isEnabled }) =>
			(checkEnabled(isEnabled, activeChannel) && !isGlobalMode) ||
			(isGlobalMode && id === 'global')
	);

	const openDocPage = () => {
		window.open('http://knowledge.crunchplatform.eu');
	};

	const hasUploads = JSON.parse(
		window._ENV_.REACT_APP_FILE_UPLOADER_ENABLED.replaceAll("'", '')
	).includes(activeChannel);

	return (
		<div className="fixed w-40 h-screen flex flex-col justify-between bg-white border-r border-ca-black border-opacity-5 z-10">
			<div>
				<div className="flex justify-center py-10">
					<PlatformSelect activePlatform={activePlatform} />
				</div>
				<ul className="flex flex-col py-10 group">
					{activeLinks.map(({ id, ...rest }) => (
						<SidebarLink key={id} {...rest} />
					))}
				</ul>
			</div>
			<div className="flex justify-center items-center p-10 flex-col">
				{activePlatform === 'Markdown' && isSuperAdmin && (
					<Tooltip content="User management">
						<button
							className={clsx(
								'p-4 border-2 rounded-full hover:border-ca-purple focus:border-ca-purple group transition-colors mb-2',
								location.pathname.startsWith('/user-management')
									? 'border-ca-purple'
									: 'border-ca-silver'
							)}
							type="button"
							onClick={() => history.push('/user-management')}
						>
							<UserManagementBold
								className={clsx(
									'w-4 group-hover:text-ca-purple transition-colors',
									location.pathname.startsWith('/user-management')
										? 'text-ca-purple'
										: 'text-ca-gray'
								)}
							/>
						</button>
					</Tooltip>
				)}
				{
					// According to Benoit this is a temp solution until the UI designer has given his input
					// Lets see if that holds up ;)
					activePlatform === 'Markdown' && hasUploads && (
						<Tooltip content="Upload data">
							<button
								className={clsx(
									'p-4 border-2 rounded-full hover:border-ca-purple focus:border-ca-purple group transition-colors mb-2',
									location.pathname.startsWith('/uploads')
										? 'border-ca-purple'
										: 'border-ca-silver'
								)}
								type="button"
								onClick={() => history.push('/uploads')}
							>
								<UploadBold
									className={clsx(
										'w-4 group-hover:text-ca-purple transition-colors',
										location.pathname.startsWith('/uploads')
											? 'text-ca-purple'
											: 'text-ca-gray'
									)}
								/>
							</button>
						</Tooltip>
					)
				}
				<Tooltip content="Link to Knowledge Base">
					<button
						className="p-4 border-2 border-ca-silver rounded-full hover:border-ca-purple focus:border-ca-purple group transition-colors mb-2"
						type="button"
						onClick={openDocPage}
					>
						<HelpIcon className="w-4 text-ca-gray group-hover:text-ca-purple transition-colors" />
					</button>
				</Tooltip>
				<LogOutButton />
			</div>
		</div>
	);
};

export default Sidebar;
