import Text from '../../shared/components/Text/Text';
import Title from '../../shared/components/Title/Title';

const UserManagementHeader = () => (
	<div className="flex justify-between">
		<div className="flex flex-col gap-2">
			<Title>User Management</Title>
			<Text className="leading-none" size="text-lg" type="secondary">
				Doing a lil&apos; admin work today? 👨‍💼
			</Text>
		</div>
	</div>
);

export default UserManagementHeader;
