import { useAuth0 } from '@auth0/auth0-react';

import useJWT from '../../data/jwt';

import LogOutIcon from '../Icons/LogOut';

// TODO WARD
// Temp button
const LogOutButton = () => {
	const { logout: auth0Logout } = useAuth0();
	const { setJWT } = useJWT();

	const logout = () => {
		setJWT({ jwt: null });
		auth0Logout({ returnTo: window.location.origin });
	};

	return (
		<button
			className="p-4 border-2 border-ca-silver rounded-full hover:border-ca-purple focus:border-ca-purple group transition-colors"
			type="button"
			onClick={logout}
		>
			<LogOutIcon className="w-4 text-ca-gray group-hover:text-ca-purple transition-colors" />
		</button>
	);
};

export default LogOutButton;
