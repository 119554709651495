import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
import { useQuery } from 'react-query';
import { GET_MY_ACCESS } from '../api/users';

import useChannelStore from '../data/channels';
import useModal from '../hooks/useModal';

import Modal from '../components/Modal/Modal';
import CircularProgress from '../components/Progress/CircularProgress';
import LogOutButton from '../components/Sidebar/LogOutButton';
import Text from '../components/Text/Text';
import Tooltip from '../components/Tooltip/Tooltip';
import useStrategyStore from '../data/strategies';

const ChannelContext = createContext();

const ChannelProvider = ({ children }) => {
	const [counter, setCounter] = useState(0);
	const {
		setChannels,
		activeChannel,
		setActiveChannel,
		reset: resetChannelStore,
	} = useChannelStore();
	const { reset: resetStrategyStore } = useStrategyStore();
	const [prevActiveChannel, setPrevActiveChannel] = useState(activeChannel);
	const { open, close } = useModal();
	const IS_STRATOS_ENABLED = window._ENV_.REACT_APP_STRATOS_ENABLED;
	const { isAuthenticated } = useAuth0();

	// TODO WARD: i removed the stale time, I think that was a big brain move, we should discuss
	const { isFetching } = useQuery('my-access', GET_MY_ACCESS, {
		enabled: IS_STRATOS_ENABLED && isAuthenticated,
		onSuccess: ({ channels }) => {
			if (channels.length === 0) {
				resetChannelStore();
			}

			setChannels(channels);
			// check if the active channel from cache exists
			const active = channels.find((c) => c === activeChannel);

			// if no active channel was set, default set it to the first channel in the list
			if (!active) {
				setActiveChannel(channels[0] ?? '');
			}
		},
	});

	// active channel changed
	if (activeChannel && activeChannel !== prevActiveChannel) {
		setPrevActiveChannel(activeChannel);
		open(
			<Modal.Root>
				<Modal.Content>
					<Modal.Title>
						<span className="block text-center">Switching channel</span>
					</Modal.Title>
					<div className="flex flex-wrap justify-center text-center space-y-8">
						<CircularProgress
							onAnimationEnded={() => setCounter((i) => i + 1)}
						/>
						<Text className="w-full">
							Just a moment please. This won&apos;t take long.
						</Text>
					</div>
				</Modal.Content>
			</Modal.Root>
		);

		resetStrategyStore();
	}

	// close the loading modal after a single iteration of the spinner
	if (counter >= 1) {
		close();
		setCounter(0);
	}

	if (!IS_STRATOS_ENABLED) {
		return children;
	}

	// TODO WARD/KILIAN: nullable active channel, no empty string
	if (isFetching && activeChannel === '') {
		return (
			<div className="min-h-screen flex justify-center items-center">
				<CircularProgress />
			</div>
		);
	}

	// TODO WARD/KILIAN: make this a proper screen and component
	if (activeChannel === '') {
		return (
			<div className="min-h-screen flex flex-col justify-center items-center gap-10">
				<img
					alt="No access, damn"
					src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExeThjNzNiYnA2NnBtNm8wZGx4bm9jdWNmZWxhZnR3NnluamE1OTQxYSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/cK0QSfVaTl62A/giphy.gif"
					width="480"
					height="360"
					className="rounded-lg"
				/>
				<Text>
					<span>You have not been assigned access to any channels. </span>
					<a href="mailto:support@crunch.fashion" className="underline">
						Please contact support@crunch.fashion.
					</a>
				</Text>
				<Tooltip content="Log out">
					<div>
						<LogOutButton />
					</div>
				</Tooltip>
			</div>
		);
	}

	return children;
};

ChannelProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export { ChannelContext, ChannelProvider };
